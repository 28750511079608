<template>
  <div class="card_balance active">
    <div class="top">
      <div class="icon">
        <img src="../../assets/profile_assets/credit-card-hand.svg" alt="" />
      </div>

      <div class="close" @click="closeSidebar">
        <img src="../../assets/profile_assets/closeSidebar.svg" alt="" />
      </div>
    </div>

    <div class="step-one" v-if="step === 1 ">
      <h2 class="mb-16">{{ $localize('profile')['cardAdd'] }}</h2>

      <ValidationObserver class="content mb-16" v-slot="{ handleSubmit, invalid }">
        <label for="card-number">{{ $localize('card-sign')['number'] }}</label>
        <ValidationProvider
          name="card"
          v-slot="{errors}"
          rules="required"
        >
          <input
            v-mask="`#### #### #### ####`"
            v-model="card.number"
            id="card-number"
            type="text"
            class="form-control mb-12 mt-8"
            placeholder="8600 0000 0000 0000"

          >
          <p class="card-error mb-4">{{ errors[0] }}</p>
        </ValidationProvider>

        <label for="card-date">{{ $localize('card-sign')['exp'] }}</label>
        <ValidationProvider
          name="card"
          v-slot="{errors}"
          rules="required|date_check"
        >
          <input
            v-model="card.date"
            v-mask="'##/##'"
            id="card-date"
            type="text"
            class="form-control mb-12 mt-8"
            placeholder="00/00"
            :disabled="card.number.length < 19"
          >
          <p class="card-error mb-4">{{ errors[0] }}</p>
        </ValidationProvider>
        <main-button
          @click="handleSubmit(add)"
          :class="{ 'btn-loading': loading }"
          :disabled="invalid"
        >
          {{ $localize('button')['continue'] }}
        </main-button>


      </ValidationObserver>
    </div>

    <div class="step-two" v-if="step === 2">
      <label for="card-sms-code">{{ $localize('card-sign')['confirmSms'] }}</label>
      <input
        v-model="card.smsCode"
        v-mask="'####'"
        id="card-sms-code"
        type="text"
        class="form-control mb-12 mt-8"
        placeholder="00 00"
      >
      <main-button
        @click="confirm"
        :class="{ 'btn-loading': loading }"
        :disabled="loading || card.smsCode.length < 4"
      >
        {{ $localize('button')['send'] }}
      </main-button>
    </div>

    <!--  final step  -->
    <div class="success" v-if="step === 3">
      <img src="../../assets/profile_assets/success.svg" alt="" />
      <h3>{{ $localize('card-sign')['successAdded'] }}</h3>
      <main-button
        @click="closeSidebar"
        :class="{ 'btn-loading': loading }"
        :disabled="loading"
      >
        {{ $localize('button')['close'] }}
      </main-button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import MainButton from '../buttons/MainButton';
import { ValidationObserver } from 'vee-validate';
import BuyerService from '@/services/buyer.service';

export default {
  components: { MainButton, ValidationObserver },
  props: {
    active: { type: Boolean },
  },
  data: () => ({
    step: 1,
    card_add_api: `${process.env.VUE_APP_API_DOMAIN}/api/v2/card/add`,
    card_confirm_api: `${process.env.VUE_APP_API_DOMAIN}/api/v2/card/confirm`,
    loading: false,
    disabledClass: true,
    errors: [],
    card: {
      hasAdded: false,
      hasConfirmed: false,
      number: '',
      date: '',
      smsCode: '',
      token: '',
    },
    api_token: null,
  }),
  methods: {
    async add() {
      const card78Number = this.card.number.split(' ')[1].slice(2, 4);
      const cardNumberFirstPrefix = this.card.number.split(' ')[0];
      const isUzCard = cardNumberFirstPrefix === '8600';
      const isCorporate = card78Number === '32' || card78Number === '08';

      if (this.card.number !== '' && this.card.date !== '') {
        if (isUzCard && !isCorporate) {

          try {
            this.loading = true;

            const { data: card } = await axios.post(this.card_add_api, {
                card_number: this.card.number,
                card_valid_date: this.card.date,
              }, {
                headers: {
                  'Content-Language': this.$i18n.locale,
                },
              },
            );

            if (card.status === 'success') {
              this.loading = false
              this.card.hasAdded = true
              this.card.token = card.card_token
              this.step++
            } else {
              this.loading = false;
              if (card.response && card.response.errors[0] === 'card_already_exists') {
                this.$toastError(this.$localize('card_already_exists'));
                this.loading = false;
              } else if (card.response && card.response.errors[0]) {
                this.$toastError(card.response.errors[0]);
              } else {
                this.$toastError(card.info[0]);
                this.loading = false;
              }
            }
          } catch (e) {
            this.loading = false;
            this.$toastError(e.message);
          }
        } else {

          if (!isUzCard) {
            try {
              this.loading = true;
              const { data: card } = await axios.post(this.card_add_api, {
                  card_number: this.card.number,
                  card_valid_date: this.card.date,
                }, {
                  headers: {
                    'Content-Language': this.$i18n.locale,
                  },
                },
              );

              if (card.status === 'success') {
                this.loading = false;
                this.card.hasAdded = true;
                this.card.token = card.card_token;
                this.step++
              } else {
                this.loading = false;
                this.$toastError(card.response.errors[0]);

              }

            } catch (e) {
              this.loading = false;
              this.$toastError(e.message);
            }
          } else {
            this.$toastError(this.$localize('error')['cardAdd']['c_card_error']);
          }
        }
      } else {
        this.loading = false;

        this.$toastError(this.$localize('error')['empty']);
      }
    },
    async confirm() {
      if (this.card.smsCode !== '') {
        try {
          this.loading = true;

          const { data: response } = await axios.post(this.card_confirm_api, {
            card_number: this.card.number,
            card_valid_date: this.card.date,
            api_token: this.api_token,
            code: this.card.smsCode,
            card_token: this.card.token,
          });

          if (response.status === 'success') {
            this.loading = false;
            this.card.hasConfirmed = true;
            this.step++
            await BuyerService.cards();
          } else {
            let errorMessage = response.response.errors[0];
            if (response.response.errors[0].includes(' ')) {
              errorMessage = response.response.errors[0].replace(/\s+/g, '_').toLowerCase();
            }
            this.$toastError(this.$localize('error')[errorMessage]);
            this.loading = false;
          }

        } catch (e) {
          this.loading = false;
          this.$toastError(e.message);
        }
      } else {
        this.$toastError(this.$localize('error')['empty']);
      }
    },
    closeSidebar() {
      this.$emit('addClose', false);
    },
  },
  created() {
    this.api_token = this.$cookieGet('token');
  },
};
</script>

<style lang="scss" scoped>
  .close {
    cursor: pointer;
  }
  .card_balance {
    padding: 40px 32px 32px 32px;
    max-width: 400px;
    background: $white;
    width: 400px;
    min-height: calc(100vh - 96px);
    position: fixed;
    top: 96px;
    right: 0;
    z-index: 1000;
    transform: translateX(0px);
    transition: 0.4s;
    overflow-x: scroll;

    &.v-enter-active,
    &.v-leave-active {
      transition: transform 300ms ease;
    }

    &.v-enter,
    &.v-leave-to {
      transform: translateX(500px);
    }

    .card-body {
      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 8px;
      }
    }

    ul.toggle {
      width: 100%;
      display: flex;
      margin-bottom: 24px;

      li {
        width: 50%;

        &:first-child {
          a {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
          }
        }

        &:last-child {
          a {
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
          }
        }

        a {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
          border: 1px solid #6610F5;
          color: $main;

          &.toggle {
            color: white;
            background: $main;
          }
        }
      }
    }

    .info-card {
      width: 100%;
      padding: 16px 12px;
      background: $grey;
      border-radius: 8px;
      display: flex;
      align-items: center;

      .icon {
        padding: 8px;
        background: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
      }

      .info_title {
        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: $black;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: $main;
        }
      }
    }

    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .icon {
        padding: 6px;
        background: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 24px;
      }
    }

    .form_input {
      margin-bottom: 32px;
      width: 100%;

      .form-label {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }

      small {
        color: red;
      }
    }
  }

  .success {
    text-align: center;
    margin-top: 50px;

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-top: 28px;
      margin-bottom: 40px;
    }
  }

  .swiper-container {
    padding-bottom: 35px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .swiper-slide {
    .card {
      padding: 16px;
      border-radius: 8px;

      &.whiteThema {
        color: white;
      }

      .card_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 55px;

        .card_left {
          h3 {
            font-size: 16px;
            line-height: 19px;
          }

          p {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
          }
        }

        .card_right {
          .frame {
            width: 72px;
            height: 32px;
            background-repeat: no-repeat;
            background-position: top right;
            background-size: contain;
          }
        }
      }

      .card_bottom {
        display: flex;
        justify-content: space-between;

        .card_left {
          font-weight: bold;
          font-size: 14px;
          line-height: 28px;
        }

        .card_right {
          font-weight: bold;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }

  button:disabled {
    background-color: #cccc !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }

  button.p-button {
    cursor: pointer;
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: 100%;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: 0.2s;
    border: none;

    &:hover {
      background: $main-light;
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  .card-error {
    color: red;
    font-size: 14px;
    padding: 0;
    margin-top: -8px;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: 15px;
    font-weight: 500;
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 575px) {
    .card_balance {
      padding: 16px;
      padding-top: 24px;
      max-width: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      top: auto;
      transform: translateX(0px);
      transform: translateY(2000px);
      overflow: auto;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      min-height: auto;

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $black;
        opacity: 0.3;
        border-radius: 4px;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        width: 56px;
        height: 4px;
      }

      &.active {
        transform: translateY(0px);
      }

      .top {
        display: none;
      }

      .card-body {
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.38px;
          margin-bottom: 8px;
        }
      }

      ul.toggle {
        margin-bottom: 12px;

        li {
          a {
            padding: 11px 12px;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.154px;
          }
        }
      }

      .form_input {
        margin-bottom: 16px;

        .form-control {
          padding: 10px 6px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;

          &::placeholder {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
          }
        }
      }

      .info-card {
        padding: 12px 10px;

        .icon {
          img {
            width: 20px;
            height: 20px;
          }
        }

        .info_title {
          h3 {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.01em;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
          }
        }
      }
    }
    .swiper-container {
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 400px) {
    .swiper-slide {
      .card {
        .card_top {
          margin-bottom: 60px;

          .card_left {
            h3 {
              font-size: 14px;
              line-height: 16px;
            }

            p {
              font-weight: bold;
              font-size: 19px;
              line-height: 23px;
            }
          }
        }

        .card_bottom {
          .card_left {
            font-size: 15px;
            line-height: 28px;
          }

          .card_right {
            font-size: 15px;
            line-height: 28px;
          }
        }
      }
    }
  }
</style>
