var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-ml-4 col-lg-4 col-md-4 col-sm-6 mb-16 card_paycoin_bonus"},[_c('div',{staticClass:"profile-progress_card"},[_c('div',{staticClass:"left"},[_vm._m(0),_c('div',{staticClass:"title"},[_c('h4',[_vm._v(_vm._s(_vm.$localize("profile")["sales"]))]),_c('p',[_vm._v(_vm._s(_vm.sale)+"%")])])]),_c('div',{staticClass:"right"},[_c('router-link',{attrs:{"to":{ name: 'profile.paycoin-sale' }}},[_vm._v(" "+_vm._s(_vm.$localize("button")["upgrade"])+" ")])],1),_vm._m(1)])]),_c('div',{staticClass:"col-xl-3 col-ml-4 col-lg-4 col-md-4 col-sm-6 mb-16 card_paycoin_bonus"},[_c('div',{staticClass:"profile-progress_card"},[_c('div',{staticClass:"left"},[_vm._m(2),_c('div',{staticClass:"title"},[_c('h4',[_vm._v(_vm._s(_vm.$localize("profile")["maxPeriod"]))]),_c('p',[_vm._v(_vm._s(_vm.months))])])]),_c('div',{staticClass:"right"},[_c('router-link',{attrs:{"to":{ name: 'profile.paycoin-sale' }}},[_vm._v(" "+_vm._s(_vm.$localize("button")["upgrade"])+" ")])],1),_vm._m(3)])]),_c('div',{staticClass:"col-xl-3 col-ml-4 col-lg-4 col-md-4 col-sm-12 mb-16 card_paycoin_bonus"},[_c('div',{staticClass:"profile-progress_card"},[_c('div',{staticClass:"left"},[_vm._m(4),_c('div',{staticClass:"title"},[_c('h4',[_vm._v(_vm._s(_vm.$localize("profile")["limitPaycoin"]))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("currency", { price: _vm.limit }))+" ")])])]),_c('div',{staticClass:"right"},[_c('router-link',{attrs:{"to":{ name: 'profile.paycoin-sale' }}},[_vm._v(" "+_vm._s(_vm.$localize("button")["upgrade"])+" ")])],1),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/profile_assets/sale.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-inner",staticStyle:{"width":"30%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/profile_assets/calendarProfile.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-inner",staticStyle:{"width":"30%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/profile_assets/price-tag.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-inner",staticStyle:{"width":"30%"}})])
}]

export { render, staticRenderFns }