<template>
  <div class="profile">
    <div class="container fluid">
      <h1>{{ $localize("profile")["title"] }}</h1>
      <div class="row">
        <div class="col-xl-7 col-ml-7">
          <div class="profile-card">
            <input
              accept=".jpg, .jpeg, .png, .svg"
              @change="uploadPhoto($event)"
              type="file"
              id="userPhoto"
              style="display: none"
            />
            <div class="card-left">
              <label class="profile-user_photo" for="userPhoto">
                <img
                  ref="userPhoto"
                  :src="profilePhoto ? profilePhoto : profilePhotoDefault"
                  alt=""
                />
                <div class="bottom">
                  <img
                    class="camera"
                    src="@/assets/profile_assets/camera.svg"
                    alt=""
                  />
                </div>
              </label>
              <loading v-if="!userInfo" size="sm" />
              <div class="profile-info" v-else>
                <h4>
                  {{ userInfo?.name || '' }}
                  {{ userInfo?.surname }}
                </h4>
                <a :href="'tel:' + userInfo?.phone">
                  {{ userInfo?.phone || '' }}
                </a>
              </div>
            </div>

            <div class="card-right">
              <div class="balance-top">
                <div class="left">
                  <loading v-if="!balanceAll" size="sm" />
                  <div v-else class="balance-info">
                    <h4>{{ $localize('profile')['limit'] }}</h4>
                    <p>
                      {{ $t('currency', { price: balanceAll }) }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- Hidden until better times (Task 118844) -->
              <!-- <h4 class="bonus-points">
                {{ localize('profile')['bonusPoints'] }}
                <router-link
                  :to="{ name: 'profile.faq' }"
                  style="margin-left: 4px"
                >
                  <img
                    src="../../assets/profile_assets/circle-question.svg"
                    alt=""
                  />
                </router-link>
              </h4>

              <p>{{ paycoin.paycoin }} Paycoin</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="balance-body">
      <div class="limit">
        <h3>{{ $localize('balancePopup')['limit'] }}</h3>
        <h4>{{ $localize('balancePopup')['possible'] }}</h4>
        <p>
          {{ $t('currency', { price: $numberFormat(balance.installment) }) }}
        </p>
      </div>
      <div class="installment">
        <div class="top">
          <h3>{{ $localize('balancePopup')['personalBalance'] }}:</h3>

          <router-link
            :to="{ name: 'profile.balance-up' }"
            class="link cursor-pointer"
          >
            <span>{{ $localize('balancePopup')['topUp'] }}</span>
            <img src="@/assets/profile_assets/add.svg" alt="" />
          </router-link>
        </div>

        <h4>{{ $localize('balancePopup')['yourDeposit'] }}</h4>

        <div class="price">
          {{ $t('currency', { price: $numberFormat(balance.deposit) }) }}
        </div>
      </div>
    </div>

    <!-- Hidden until better times (Task 118844) -->
    <BonusInfo
      v-if="false"
      :sale="paycoin.PaycoinSalePage"
      :months="paycoin.month"
      :limit="balanceAll"
    />

    <!--    <loading v-if="loading" size="lg" />-->

    <div v-if="cardUser.length > 0" class="container fluid">
      <div class="row justify-content-between">
        <div class="col-6">
          <h3 class="card">{{ $localize('profile')['myCards'] }}</h3>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center justify-content-end">
            <span>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 8H15V16H8V17H15V24H16V17L24 17V16L16 16V8Z"
                  fill="#6610F5"
                />
              </svg>
            </span>
            <span class="cursor-pointer mb-5 text-main" @click="active = true">
              {{ $localize('profile')['cardAdd'] }}
            </span>
          </div>
        </div>
      </div>

      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(card, i) in cardUser" :key="i">
          <div
            class="card"
            :style="`background:url(${card.style}) no-repeat center center / cover;`"
            :class="card.theme"
          >
            <div class="card_top">
              <div class="card_left">
                <h3>
                  {{ card.title || $localize('profile')['cardTitleDefault'] }}
                </h3>
                <p>{{ $numberFormat(card.balance) }}</p>
              </div>
              <div class="card_right">
                <div
                  class="frame"
                  :style="`background-image:url(${card.logo});`"
                ></div>
              </div>
            </div>
            <div class="card_bottom">
              <div class="card_left">
                {{ card.pan }}
              </div>
              <div class="card_right">
                {{ card.exp }}
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <transition>
      <card-add-bar v-if="active" :active="active" @addClose="openAddCardBar" />
    </transition>

    <my-overlay @closeOverlay="active = false" :active="active" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';

import profilePhotoDefault from '@/assets/profile_assets/Vector.png';
import paycoin from '@/data/paycoin.js';

import CardAddBar from '@/components/bars/CardAddBar';
import BonusInfo from '@/components/profile/BonusInfo';
import MyOverlay from '@/components/UI/MyOverlay';

import BuyerService from '@/services/buyer.service';

export default {
  name: 'profile',
  components: { MyOverlay, Swiper, SwiperSlide, CardAddBar, BonusInfo },
  data: () => ({
    profilePhoto: localStorage.getItem("profilePhoto") || null,
    userPhoto: null,
    profilePhotoDefault,
    paycoin,
    swiperOption: {
      slidesPerView: 3,
      spaceBetween: 32,
      grabCursor: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        // when window width is >= 576px
        576: {
          slidesPerView: 1.5,
          spaceBetween: 30,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        1300: {
          slidesPerView: 2.8,
          spaceBetween: 32,
        },
        1600: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
      },
    },
    item: [],
    balanceAll: '',
    active: false,
  }),
  methods: {
    uploadPhoto(e) {
      if (!e.target.files.length) {
        return;
      }
      this.userPhoto = Array.from(e.target.files);

      this.userPhoto.forEach((file) => {
        if (file.type.match('image')) {
          const render = new FileReader();
          render.onload = (ev) => {
            localStorage.setItem('profilePhoto', ev.target.result);
            window.location.reload();
          };
          render.readAsDataURL(file);
        }
      });
    },
    openAddCardBar(isActive) {
      this.active = isActive;
    },
  },
  computed: {
    ...mapGetters('buyers', ['userInfo', 'cardUser', 'balance']),
  },
  async created() {
    await this.$store.dispatch("buyers/userStatus");
    await BuyerService.balance();
    await BuyerService.cards();
    await this.$store.dispatch("buyers/userStatus");
    this.balanceAll = await BuyerService.balanceAll();
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .balance-body {
    display: none;
    margin: 0 16px 16px;
    padding: 24px 16px;
    background: $white;
    border-radius: 8px;

    @media (max-width: $mobile) {
      display: block;
    }

    .limit {
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(30, 30, 30, 0.1);

      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: $black;
      }

      h4 {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 12px;
      }

      p {
        font-weight: 900;
        font-size: 20px;
        line-height: 25px;
        color: $black;

        span {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: $black;
          opacity: 0.3;
          margin-left: 12px;
          display: inline-block;
        }
      }
    }

    .installment {
      padding-top: 13px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link {
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          line-height: 111.63%;
          color: $main;
        }

        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 28px;
          color: $black;
        }
      }

      h4 {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $black;
        margin-bottom: 12px;
      }

      .price {
        font-weight: 900;
        font-size: 20px;
        line-height: 25px;
        color: $main;

        span {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: $black;
          opacity: 0.3;
          display: inline-block;
          margin-left: 12px;
        }
      }
    }
  }

  h3.card {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $black;
    margin-bottom: 16px;
  }

  .mb-16:last-child {
    margin-bottom: 16px;
  }

  padding: 48px 44px;
  min-height: calc(100vh - 96px);
  background: $grey;
  width: 100%;

  h1 {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: $black;
    margin-bottom: 24px;
  }

  &-card {
    padding: 24px 80px 24px 24px;
    background: $white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .card-left {
      display: flex;
      align-items: center;
      margin-right: 40px;
    }

    .card-right {
      h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: $black;
        display: flex;
        align-items: center;
      }

      p {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: 0.326451px;
        color: $main;
      }
    }
  }

  &-info {
    margin-left: 20px;
    text-transform: capitalize !important;

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.38px;
      color: $black;
      margin-bottom: 4px;
    }

    a {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.078px;
      color: $black;
    }
  }

  &-user_photo {
    position: relative;
    display: inline-block;
    min-width: 72px;
    max-width: 72px;
    height: 72px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;

    img:not(.camera) {
      width: 100%;
      height: auto;
    }

    .bottom {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 30%;
      background: rgba(0, 0, 0, 0.32);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-progress_card {
    background: $white;
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
    margin-bottom: 24px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      margin-bottom: 4px;

      h4 {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.078px;
        color: $black;
        margin-left: 8px;
      }

      p {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.078px;
        color: $main;
        margin-left: 8px;
      }
    }

    .right {
      a {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: $main;
      }
    }

    .progress {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: #eaeaea;
      overflow: hidden;

      &-inner {
        height: 100%;
        background: $main;
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  .card {
    padding: 16px;
    border-radius: 8px;

    &.whiteThema {
      color: white;
    }

    .card_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 88px;

      .card_left {
        h3 {
          font-size: 16px;
          line-height: 19px;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .card_right {
        .frame {
          width: 72px;
          height: 32px;
          background-repeat: no-repeat;
          background-position: top right;
          background-size: contain;
        }
      }
    }

    .card_bottom {
      display: flex;
      justify-content: space-between;

      .card_left {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }

      .card_right {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

.add-card-container {
  text-align: center;
  margin: auto 0 auto 32px;

  .add-cart-button {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
    background-color: $main;
    padding: 10px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}

@media (max-width: 991px) {
  .profile {
    &-progress_card {
      .right {
        a {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .profile {
    padding: 27px 0px;
    width: auto;
    margin-left: -16px;
    margin-right: -16px;

    &-progress_card {
      padding: 16px 8px;

      &:nth-child(2) {
        padding-bottom: 10px;
      }

      .left {
        .img {
          width: 20px;
          height: 20px;
        }

        .title {
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .profile {
    h1 {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;
    }
  }
  .profile-card {
    padding: 24px 16px;
    flex-wrap: wrap;

    .card-left {
      margin-right: 15px;
    }
  }
  .profile-progress_card {
    .left {
      h4 {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.078px;
        color: $black;
      }

      p {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.078px;
        color: $main;
      }
    }

    .right {
      a {
        display: block;
      }
    }
  }
  .profile-card .card-right {
    h3 {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.24px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.326451px;
    }
  }
}

@media (max-width: 469px) {
  .profile-card {
    padding: 24px 16px;
    flex-wrap: wrap;

    .card-left {
      margin-right: 15px;
      margin-bottom: 32px;
    }
  }
}
</style>
