export default {
  paycoin: 0,
  limit: 3000000,
  sale: 0,
  month: 12,
  bonuce: [
    {
      type: "PaycoinSalePage.vue",
      item: [
        {
          bonus: 1,
          received: false,
          active: false,
          disabled: true,
        },
        {
          bonus: 3,
          received: false,
          active: false,
          disabled: true,
        },
        {
          bonus: 5,
          received: false,
          active: false,
          disabled: true,
        },
      ],
    },
    {
      type: "month",
      item: [
        {
          bonus: 1,
          received: false,
          active: true,
          disabled: false,
        },
        {
          bonus: 1,
          received: false,
          active: false,
          disabled: true,
        },
        {
          bonus: 1,
          received: false,
          active: false,
          disabled: true,
        },
      ],
    },
    {
      type: "limit",
      item: [
        {
          bonus: 25,
          received: false,
          active: true,
          disabled: false,
        },
        {
          bonus: 25,
          received: false,
          active: false,
          disabled: true,
        },
        {
          bonus: 25,
          received: false,
          active: false,
          disabled: true,
        },
      ],
    },
  ],
};
