<template>
  <div class="container fluid">
    <div class="row">
      <div
        class="
          col-xl-3 col-ml-4 col-lg-4 col-md-4 col-sm-6
          mb-16
          card_paycoin_bonus
        "
      >
        <div class="profile-progress_card">
          <div class="left">
            <div class="img">
              <img src="../../assets/profile_assets/sale.svg" alt="" />
            </div>

            <div class="title">
              <h4>{{ $localize("profile")["sales"] }}</h4>

              <p>{{ sale }}%</p>
            </div>
          </div>

          <div class="right">
            <router-link :to="{ name: 'profile.paycoin-sale' }">
              {{ $localize("button")["upgrade"] }}
            </router-link>
          </div>

          <div class="progress">
            <div class="progress-inner" style="width: 30%"></div>
          </div>
        </div>
      </div>

      <div
        class="
          col-xl-3 col-ml-4 col-lg-4 col-md-4 col-sm-6
          mb-16
          card_paycoin_bonus
        "
      >
        <div class="profile-progress_card">
          <div class="left">
            <div class="img">
              <img
                src="../../assets/profile_assets/calendarProfile.svg"
                alt=""
              />
            </div>

            <div class="title">
              <h4>{{ $localize("profile")["maxPeriod"] }}</h4>
              <p>{{ months }}</p>
            </div>
          </div>

          <div class="right">
            <router-link :to="{ name: 'profile.paycoin-sale' }">
              {{ $localize("button")["upgrade"] }}
            </router-link>
          </div>

          <div class="progress">
            <div class="progress-inner" style="width: 30%"></div>
          </div>
        </div>
      </div>

      <div
        class="
          col-xl-3 col-ml-4 col-lg-4 col-md-4 col-sm-12
          mb-16
          card_paycoin_bonus
        "
      >
        <div class="profile-progress_card">
          <div class="left">
            <div class="img">
              <img src="../../assets/profile_assets/price-tag.svg" alt="" />
            </div>

            <div class="title">
              <h4>{{ $localize("profile")["limitPaycoin"] }}</h4>
              <p>
                <!--todo paycoin limit -->
                {{ $t("currency", { price: limit }) }}
              </p>
              <!-- 
              <h4 class="bonus-points">
                Бонусные баллы:
                <router-link
                  to="/profile/quest_paycoin"
                  style="margin-left: 4px"
                >
                  <img
                    src="../assets/profile_assets/circle-question.svg"
                    alt=""
                  />
                </router-link>
              </h4>
              <p>{{ paycoin.paycoin }} Paycoin</p>
              -->
            </div>
          </div>

          <div class="right">
            <router-link :to="{ name: 'profile.paycoin-sale' }">
              {{ $localize("button")["upgrade"] }}
            </router-link>
          </div>

          <div class="progress">
            <div class="progress-inner" style="width: 30%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BonusInfo',
  props: ['sale', 'months', 'limit']
}
</script>

<style>
</style>